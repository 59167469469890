<template>
  <div class="block border-t border-gray-200 dark:border-gray-700">
    <div class="py-4">
      <div class="flex items-center justify-between mb-4 flex-wrap">
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">Piezīmes</h3>

        <div class="ml-auto">
          <template v-if="item.notes">
            <Button icon="pencil" @click="showForm('EditNotes')" />
          </template>
        </div>
      </div>

      <template v-if="!displayForm('AddNotes') && !displayForm('EditNotes')">
        <template v-if="item.notes">
          <div class="flex">
            <div class="flex flex-wrap">
              <item-text :text="item.notes" />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="w-full">
            <p class="text-center text-lg text-gray-400 mb-3">Inventāram nav pievienotas piezīmes</p>
            <button @click="showForm('AddNotes')" type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out">
              Pievienot
            </button>
          </div>
        </template>
      </template>

      <template v-if="displayForm('AddNotes')">
        <AddItemNote :item="item" />
      </template>

      <template v-if="displayForm('EditNotes')">
        <EditItemNote :item="item" />
      </template>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ItemText from "@/components/Components/ItemText"
import AddItemNote from "@/components/Stock/Inventory/InventoryItem/InventoryItemNotes/AddItemNote"
import EditItemNote from "@/components/Stock/Inventory/InventoryItem/InventoryItemNotes/EditItemNote"

export default {
  name: "ShowInventoryItemNotes",
  components: {
    ItemText,
    AddItemNote,
    EditItemNote,
  },
  props: ['item'],
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },

  }
}
</script>

<style>

</style>